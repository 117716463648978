import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private fire: AngularFireAuth
  ) { }

  login(username, password) {
    return this.fire.auth.signInWithEmailAndPassword(username, password);
  }

  register(username, password) {
    return this.fire.auth.createUserWithEmailAndPassword(username, password);
  }

  forgottenPassword(email) {
    return this.fire.auth.sendPasswordResetEmail(email);
  }
}
