import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'innskraning', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'forsida', loadChildren: './front/front.module#FrontPageModule' },
  { path: 'nyskraning', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'vika1', loadChildren: './weeks/week-one/week-one.module#WeekOnePageModule' },
  { path: 'vika2', loadChildren: './weeks/week-two/week-two.module#WeekTwoPageModule' },
  { path: 'vika3', loadChildren: './weeks/week-three/week-three.module#WeekThreePageModule' },
  { path: 'vika4', loadChildren: './weeks/week-four/week-four.module#WeekFourPageModule' },
  { path: 'vika5', loadChildren: './weeks/week-five/week-five.module#WeekFivePageModule' },
  { path: 'vika6', loadChildren: './weeks/week-six/week-six.module#WeekSixPageModule' },
  { path: 'verkefni1', loadChildren: './assignments/assignment-one/assignment-one.module#AssignmentOnePageModule' },
  { path: 'verkefni3', loadChildren: './assignments/assignment-three/assignment-three.module#AssignmentThreePageModule' },
  { path: 'verkefni4', loadChildren: './assignments/assignment-four/assignment-four.module#AssignmentFourPageModule' },
  { path: 'verkefni5', loadChildren: './assignments/assignment-five/assignment-five.module#AssignmentFivePageModule' },
  { path: 'verkefni6', loadChildren: './assignments/assignment-six/assignment-six.module#AssignmentSixPageModule' },
  { path: 'verkefni7', loadChildren: './assignments/assignment-seven/assignment-seven.module#AssignmentSevenPageModule' },
  { path: 'verkefni8', loadChildren: './assignments/assignment-eight/assignment-eight.module#AssignmentEightPageModule' },
  { path: 'verkefni9', loadChildren: './assignments/assignment-nine/assignment-nine.module#AssignmentNinePageModule' },
  { path: 'verkefni10', loadChildren: './assignments/assignment-ten/assignment-ten.module#AssignmentTenPageModule' },
  { path: 'verkefni11', loadChildren: './assignments/assignment-eleven/assignment-eleven.module#AssignmentElevenPageModule' },
  { path: 'verkefni12', loadChildren: './assignments/assignment-twelve/assignment-twelve.module#AssignmentTwelvePageModule' },
  { path: 'fimm-thatta-likan', loadChildren: './assignments/fimm-thatta-likan/fimm-thatta-likan.module#FimmThattaLikanPageModule' },
  { path: 'hugsanaskra', loadChildren: './assignments/hugsanaskra/hugsanaskra.module#HugsanaskraPageModule' },
  { path: 'markmid', loadChildren: './assignments/markmid/markmid.module#MarkmidPageModule' },
  { path: 'virknitafla', loadChildren: './assignments/virknitafla/virknitafla.module#VirknitaflaPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
